<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="إضافة حالة للشخص">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider
                #default="{ errors }"
                name="personStatusName"
                rules="required"
              >
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label> الاسم</label>
                  <b-form-input v-model="personStatusName" id="input-default" />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6">
              <validation-provider
                #default="{ errors }"
                name="personStatusDetail"
                rules=""
              >
                <b-form-group label-for="" :state="errors.length > 0 ? false : null">
                  <label>  التفاصيل</label>
                  <b-form-textarea v-model="personStatusDetail" id="input-detail" />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12">
              <b-button variant="purple" @click="addPersobStatus">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إضافة </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" ">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group>
          <div class="">
            <!-- <label>بحث</label> -->
            <div>
              <b-form-input
                v-model="searchTerm"
                placeholder="بحث"
                type="text"
                class="d-inline-block"
              />
            </div>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Status -->
          <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'id'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-person-status',
                      params: { id: props.row.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                <!-- <b-dropdown-item-button >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>تعديل</span>
              </b-dropdown-item-button > -->
                <b-dropdown-item-button @click="deleteDocument(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {
  name: "",

  components: {
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    ToastificationContent,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      personStatusName: "",
      personStatusDetail: "",
      documentType: [
        {
          value: 1,
          label: "العائلة",
        },
        {
          value: 2,
          label: "الأفراد",
        },
        {
          value: 3,
          label: "كلاهما",
        },
      ],

      pageLength: 3,
      dir: false,

      columns: [
        {
          label: "الاسم  ",
          field: "name",
        },
        {
          label: "التفاصيل  ",
          field: "detail",
        },
        {
          label: "الخيارات",
          field: "id",
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: "",
      id: "",
    };
  },

  created() {
    this.getDocuments();
  },

  methods: {
    addPersobStatus() {
      let data = {
        name: this.personStatusName,
        detail: this.personStatusDetail,
      };
      this.$http.post("/api/v1/person-status", data).then((res) => {
        //console.log(res);
        this.getDocuments();
        this.$swal({
          title: "تم إضافة الحالة بنجاح",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    },
    getDocuments() {
      this.$http.get("/api/v1/person-status").then((res) => {
        console.log(res.data);
        this.rows = res.data;
      });
    },
    deleteDocument(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/person-status/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            this.getDocuments();
            this.$swal({
              title: "تم الحذف  بنجاح",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    },

    //
  },
};
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
