var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-card-code',{attrs:{"title":"إضافة حالة للشخص"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"name":"personStatusName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الاسم")]),_c('b-form-input',{attrs:{"id":"input-default"},model:{value:(_vm.personStatusName),callback:function ($$v) {_vm.personStatusName=$$v},expression:"personStatusName"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"personStatusDetail","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" التفاصيل")]),_c('b-form-textarea',{attrs:{"id":"input-detail"},model:{value:(_vm.personStatusDetail),callback:function ($$v) {_vm.personStatusDetail=$$v},expression:"personStatusDetail"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"12","xl":"12"}},[_c('b-button',{attrs:{"variant":"purple"},on:{"click":_vm.addPersobStatus}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(" إضافة ")])],1)],1)],1)],1)],1)],1),_c('b-card-code',{attrs:{"title":" "}},[_c('div',{staticClass:"custom-search d-flex justify-content-start"},[_c('b-form-group',[_c('div',{},[_c('div',[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"بحث","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])]):(props.column.field === 'id')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$router.push({
                    name: 'edit-person-status',
                    params: { id: props.row.id },
                  })}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v("عرض")])],1),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.deleteDocument(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("حذف")])],1)],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }